import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import AutoHeight from "../../component/AutoHeightView";
import { SectionView } from "../../component/Section";
import {
  STRINGS_ACTIVATION_CODES,
  STRINGS_GENERATE_ACTIVIATION_CODE,
  STRINGS_GENERATE_ACTIVIATION_CODE_DESCRIPTION,
} from "../../../data/strings/Strings";
import { CustomTextField } from "../addHomeButton/AddOrEditHomeButtonView";
import { ActivationCode } from "../../../domain/model/ActivationCode";
import { ActivationCodesViewModel } from "./ActivationCodesViewModel";
import {
  provideCreateActivationCodeUseCase,
  provideDeleteActivationCodeUseCase,
  provideGetAllActivationCodesUseCase,
} from "../../di/UseCasesModule";
import { formateDate, msToTime } from "../../../core/utils/Utils";
import {
  CheckRounded,
  CloseRounded,
  ContentCopyRounded,
  CopyAllRounded,
  CopyrightRounded,
  DeleteRounded,
  SearchRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { copyToClipboard } from "../../../core/utils/UtilsInJS";
import {
  COLOR_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_LOGIN_CARD_BLACK,
} from "../../../data/colors/Colors";

export function ActivationCodesView() {
  const viewModel = ActivationCodesViewModel(
    provideCreateActivationCodeUseCase(),
    provideGetAllActivationCodesUseCase(),
    provideDeleteActivationCodeUseCase()
  );
  return (
    <AutoHeight>
      <Box
        padding={2}
        minHeight={"40vh"}
        display={"flex"}
        alignItems={"center"}
      >
        <Dialog
          open={viewModel.showActivationCodeCreatedDialog}
          onClose={() => viewModel.setShowActivationCodeCreatedDialog(false)}
          PaperProps={{ sx: { borderRadius: 4, p: 2, minWidth: "50vw" } }}
        >
          <Stack width={"100%"} spacing={2}>
            <Stack
              direction={"row"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography fontWeight={"bold"} variant="h6">
                Created Activation Code(s)
              </Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <IconButton
                  onClick={() => {
                    viewModel.copyAll();
                  }}
                >
                  <CopyAllRounded sx={{ color: "black" }} />
                </IconButton>
                <IconButton
                  onClick={() =>
                    viewModel.setShowActivationCodeCreatedDialog(false)
                  }
                >
                  <CloseRounded />
                </IconButton>
              </Stack>
            </Stack>
            <Divider flexItem />
            {viewModel.createdActivationCodes.map((a, i) => {
              return <ActivationCodeItemView activationCode={a} index={i} />;
            })}
          </Stack>
        </Dialog>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <SectionView
              title={STRINGS_GENERATE_ACTIVIATION_CODE}
              subtitle={STRINGS_GENERATE_ACTIVIATION_CODE_DESCRIPTION}
              minHeight="28vh"
              layout={
                <Box
                  component="form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    viewModel.createActivationCode();
                  }}
                >
                  <Stack spacing={2} alignItems={"center"} pt={4}>
                    <CustomTextField
                      handleChange={(txt) => {
                        viewModel.setCreateActivationCodeModel({
                          ...viewModel.createActivationCodeModel,
                          code: txt,
                        });
                      }}
                      value={viewModel.createActivationCodeModel.code}
                      placeholder={"Custom Code (optional)"}
                      isFullWidth
                      showTitle
                    />
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      width={"100%"}
                      spacing={2}
                      sx={{ pb: 4 }}
                    >
                      <CustomTextField
                        handleChange={(txt) => {
                          viewModel.setCreateActivationCodeModel({
                            ...viewModel.createActivationCodeModel,
                            expiresIn: txt ? Number(txt) : undefined,
                          });
                        }}
                        value={viewModel.createActivationCodeModel.expiresIn?.toString()}
                        placeholder={"Expires In (days)"}
                        type="number"
                        isFullWidth
                        required
                        showTitle
                      />
                      <CustomTextField
                        placeholder={"Amount"}
                        type="number"
                        isFullWidth
                        showTitle
                        handleChange={(txt) => {
                          viewModel.setCreateActivationCodeModel({
                            ...viewModel.createActivationCodeModel,
                            bulkNumber: txt ? Number(txt) : undefined,
                          });
                        }}
                        value={viewModel.createActivationCodeModel.bulkNumber?.toString()}
                        required
                      />
                    </Stack>
                    <Button
                      type="submit"
                      variant="outlined"
                      fullWidth
                      sx={{ borderRadius: 12, p: 1.5 }}
                      disabled={viewModel.isCreatingActivationCode}
                    >
                      {viewModel.isCreatingActivationCode ? (
                        <CircularProgress size={25} color="inherit" />
                      ) : (
                        <Typography fontWeight={"bold"}>CREATE</Typography>
                      )}
                    </Button>
                  </Stack>
                </Box>
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={8} xl={8}>
            <SectionView
              title={"Activation Codes"}
              subtitle={""}
              minHeight="28vh"
              layout={
                <>
                  {viewModel.isFetchingActivationCodes ? (
                    <Grid container spacing={2}>
                      {Array.from(Array(4).keys()).map((i) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            height={"20vh"}
                            key={i}
                          >
                            <Skeleton
                              variant="rounded"
                              height={"100%"}
                              sx={{ borderRadius: 4 }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <>
                      {viewModel.activationCodes && (
                        <Grid container spacing={2}>
                          <Grid
                            alignItems={"center"}
                            justifyContent={"center"}
                            display={"flex"}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            mt={4}
                          >
                            <Box
                              component={"form"}
                              onSubmit={(e) => {
                                e.preventDefault();
                                viewModel.search();
                              }}
                              width={"100%"}
                              noValidate
                            >
                              <Stack
                                direction={"row"}
                                width={"100%"}
                                spacing={1}
                                alignItems={"center"}
                              >
                                <CustomTextField
                                  value={viewModel.searchText}
                                  placeholder="Search (code or device id)"
                                  handleChange={(t) => {
                                    viewModel.setSearchText(t);
                                  }}
                                />
                                <IconButton
                                  sx={{ border: "solid 1px gray", p: 2 }}
                                  type="submit"
                                >
                                  <SearchRounded sx={{ color: "gray" }} />
                                </IconButton>
                              </Stack>
                            </Box>
                          </Grid>
                          {viewModel.activationCodes.length === 0 ? (
                            <Stack
                              width={"100%"}
                              height={"100%"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              minHeight={"20vh"}
                            >
                              <Typography fontWeight={"bold"}>
                                NOTHING TO SHOW
                              </Typography>
                            </Stack>
                          ) : (
                            <>
                              {viewModel.activationCodes.map(
                                (activationCode, index) => {
                                  return (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={6}
                                      xl={6}
                                      key={activationCode._id}
                                    >
                                      <ActivationCodeItemView
                                        activationCode={activationCode}
                                        index={index}
                                        onDeleteActivationCode={
                                          viewModel.deleteActivationCode
                                        }
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                              <Grid
                                width={"98vw"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                display={"flex"}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                {viewModel.showLoadMoreLoading ? (
                                  <CircularProgress size={40} />
                                ) : (
                                  <>
                                    {viewModel.showLoadMoreButton && (
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          borderRadius: 22,
                                          textTransform: "none",
                                        }}
                                        onClick={viewModel.getActivationCodes}
                                      >
                                        <Typography
                                          fontWeight={"bold"}
                                          variant="h6"
                                        >
                                          Load More
                                        </Typography>
                                      </Button>
                                    )}
                                  </>
                                )}
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                </>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </AutoHeight>
  );
}

function ActivationCodeItemView({
  activationCode,
  onDeleteActivationCode,
  index,
}: {
  activationCode: ActivationCode;
  onDeleteActivationCode?: (id: string) => void;
  index: number;
}) {
  function isExpired(): boolean {
    if (!activationCode.redeemedAt) return false;
    const redeemedAtDate = activationCode.redeemedAt;
    const expiresAt = new Date(
      new Date(redeemedAtDate).getTime() +
        1000 * 24 * 60 * 60 * activationCode.expiresIn
    );

    if (expiresAt < new Date()) return true;
    return false;
  }

  function getRemainingTime(): number {
    if (!activationCode.redeemedAt) return -69;
    const redeemedAtDate = activationCode.redeemedAt;
    const expiresAt = new Date(
      new Date(redeemedAtDate).getTime() +
        1000 * 24 * 60 * 60 * activationCode.expiresIn
    );

    return expiresAt.getTime() - new Date().getTime();
  }

  const remainingDays = getRemainingTime() / (1000 * 24 * 60 * 60);

  const [remainingTimeInMs, setRemainingTimeInMs] = useState(
    getRemainingTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTimeInMs(getRemainingTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [isCopied, setIsCopied] = useState(false);
  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: 4,
        padding: 2,
        bgcolor: isExpired()
          ? "rgba(210, 47, 47, 0.2)"
          : onDeleteActivationCode
          ? "white"
          : "lightgrey",
      }}
    >
      <Stack width={"100%"} spacing={2}>
        <Stack
          direction={"row"}
          spacing={1}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Typography fontWeight={"bold"} variant="h5">
            #{index + 1}
          </Typography>

          {isExpired() && (
            <Typography fontWeight={"bold"} variant="h5" color={"red"}>
              EXPIRED
            </Typography>
          )}

          {!isExpired() && activationCode.redeemedAt && (
            <Stack
              alignItems={"center"}
              direction={remainingDays < 1 ? "column" : "row"}
              spacing={0.5}
            >
              {remainingDays < 1 && (
                <Typography color={"gray"} variant="body2">
                  Expires In
                </Typography>
              )}
              <Typography
                fontWeight={"bold"}
                variant={remainingDays < 1 ? "h6" : "h5"}
              >
                {remainingDays < 1
                  ? msToTime(remainingTimeInMs)
                  : remainingDays.toFixed(0)}
              </Typography>
              {remainingDays >= 1 && (
                <Typography color={"gray"} variant="body2">
                  Days Left
                </Typography>
              )}
            </Stack>
          )}

          {onDeleteActivationCode && (
            <IconButton
              sx={{ width: 40, height: 40, border: "solid 1px red" }}
              onClick={() => {
                onDeleteActivationCode(activationCode._id);
              }}
            >
              <DeleteRounded sx={{ color: "red" }} />
            </IconButton>
          )}
        </Stack>
        {activationCode.redeemedBy && (
          <Grid container spacing={0} alignItems={"center"}>
            <Grid item>
              <Typography color={"gray"} variant="subtitle1">
                Redeemed by
              </Typography>
            </Grid>

            <Grid item sx={{ pl: 1 }}>
              <Typography fontWeight={"bold"} variant="subtitle1">
                "{activationCode.redeemedBy}"
              </Typography>
            </Grid>
            <Grid item sx={{ pl: 1 }}>
              <Typography color={"grey"} variant="subtitle1">
                at
              </Typography>
            </Grid>

            <Grid item sx={{ pl: 1 }}>
              <Typography fontWeight={"bold"} variant="subtitle1">
                {activationCode.redeemedAt
                  ? formateDate(activationCode.redeemedAt)
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Divider flexItem />

        <Stack
          direction={"row"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-between"}
          spacing={1}
        >
          <Typography>{activationCode.code}</Typography>

          <IconButton
            sx={{
              width: 40,
              height: 40,
              border: "solid 1px " + (isCopied ? "green" : "black"),
            }}
            onClick={() => {
              setIsCopied(true);
              copyToClipboard(activationCode.code);

              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            }}
          >
            {isCopied ? (
              <CheckRounded sx={{ color: "green" }} />
            ) : (
              <ContentCopyRounded sx={{ color: "black" }} />
            )}
          </IconButton>
        </Stack>

        <Divider flexItem />

        {!isExpired() && (
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography color={"gray"} variant="body2">
              Expiration Days:
            </Typography>
            <Typography fontWeight={"bold"} variant="subtitle1">
              {activationCode.expiresIn}
            </Typography>
          </Stack>
        )}

        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography color={"gray"} variant="body2">
            Created At
          </Typography>
          <Typography fontWeight={"bold"} variant="subtitle1">
            {formateDate(activationCode.createdAt)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
