import {
  ActivationCode,
  CreateActivationCodeRequest,
} from "../../../domain/model/ActivationCode";
import { Message } from "../../../domain/model/Auth";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { ActivationCodeRepository } from "./ActivationCodeRepository";

export class ActivationCodeRepositoryImpl implements ActivationCodeRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }
  getActivationCodes(
    skip?: number,
    limit?: number,
    q?: string
  ): Promise<Response<Array<ActivationCode>>> {
    return this.apiService.getActivationCodes(skip, limit, q);
  }
  createActivationCode(
    model: CreateActivationCodeRequest
  ): Promise<Response<Array<ActivationCode>>> {
    return this.apiService.createActivationCode(model);
  }
  deleteActivationCode(id: string): Promise<Response<Message>> {
    return this.apiService.deleteActivationCode(id);
  }
}
