import NetworkApi from "../../data/network/NetworkApi";
import NetworkApiImpl from "../../data/network/NetworkApiImpl";
import { ActivationCodeRepository } from "../../data/repository/activationCode/ActivationCodeRepository";
import { ActivationCodeRepositoryImpl } from "../../data/repository/activationCode/ActivationCodeRepositoryImpl";
import { AndroidTVMessagesRepository } from "../../data/repository/androidTVMessages/AndroidTVMessagesRepository";
import { AndroidTVMessagesRepositoryImpl } from "../../data/repository/androidTVMessages/AndroidTVMessagesRepositoryImpl";
import { AndroidTVSettingsRepository } from "../../data/repository/androidTVSettings/AndroidTVSettingsRepository";
import { AndroidTVSettingsRepositoryImpl } from "../../data/repository/androidTVSettings/AndroidTVSettingsRepositoryImpl";
import { AuthRepository } from "../../data/repository/auth/AuthRepository";
import { AuthRepositoryImpl } from "../../data/repository/auth/AuthRepositoryImpl";
import { NubVisionAppsRepository } from "../../data/repository/nubVisionApps/NubVisionAppsRepository";
import { NubVisionAppsRepositoryImpl } from "../../data/repository/nubVisionApps/NubVisionAppsRepositoryImpl";
import { UploadImageRepository } from "../../data/repository/uploadImage/UploadImageRepository";
import { UploadImageRepositoryImpl } from "../../data/repository/uploadImage/UploadImageRepositoryImpl";
import {
  CreateActivationCodeUseCase,
  CreateActivationCodeUseCaseImpl,
} from "../../domain/usecase/activationCode/create/UseCase";
import {
  DeleteActivationCodeUseCase,
  DeleteActivationCodeUseCaseImpl,
} from "../../domain/usecase/activationCode/delete/UseCase";
import {
  GetAllActivationCodesUseCase,
  GetAllActivationCodesUseCaseImpl,
} from "../../domain/usecase/activationCode/getAll/UseCase";
import {
  GetAllAndroidTVMessagesUseCase,
  GetAllAndroidTVMessagesUseCaseImpl,
} from "../../domain/usecase/androidTVMessages/getAllMessages/GetAllMessagesUseCase";
import {
  SendAndroidTVMessageUseCase,
  SendAndroidTVMessageUseCaseImpl,
} from "../../domain/usecase/androidTVMessages/sendMessage/SendMessageUseCase";
import {
  AndroidTVSettingsAddHomeButtonUseCase,
  AndroidTVSettingsAddHomeButtonUseCaseImpl,
} from "../../domain/usecase/androidTVSettings/addHomeButton/AddHomeButtonUseCase";
import {
  AndroidTVSettingsChangeBackgroundImageUseCase,
  AndroidTVSettingsChangeBackgroundImageUseCaseImpl,
} from "../../domain/usecase/androidTVSettings/changeBackgroundImage/ChangeBackgroundImageUseCase";
import {
  AndroidTVSettingsChangeTitleUseCase,
  AndroidTVSettingsChangeTitleUseCaseImpl,
} from "../../domain/usecase/androidTVSettings/changeTitle/ChangeTitleUseCase";
import {
  AndroidTVSettingsDeleteHomeButtonUseCase,
  AndroidTVSettingsDeleteHomeButtonUseCaseImpl,
} from "../../domain/usecase/androidTVSettings/deleteHomeButton/DeleteHomeButtonUseCase";
import {
  GetAndroidTVSettingsUseCase,
  GetAndroidTVSettingsUseCaseImpl,
} from "../../domain/usecase/androidTVSettings/get/GetAndroidTVSettingsUseCase";
import {
  AndroidTVSettingsUpdateCurrentLauncherAppVersionUseCase,
  AndroidTVSettingsUpdateCurrentLauncherAppVersionUseCaseImpl,
} from "../../domain/usecase/androidTVSettings/updateCurrentLauncherAppVersion/UpdateCurrentLauncherAppVersionUseCase";
import {
  AndroidTVSettingsUpdateHomeButtonUseCase,
  AndroidTVSettingsUpdateHomeButtonUseCaseImpl,
} from "../../domain/usecase/androidTVSettings/updateHomeButton/UpdateHomeButtonUseCase";
import {
  ChangePasswordUseCase,
  ChangePasswordUseCaseImpl,
} from "../../domain/usecase/auth/ChangePassword/ChangePasswordUseCase";
import {
  LoginUseCase,
  LoginUseCaseImpl,
} from "../../domain/usecase/auth/Login/LoginUseCase";
import {
  AddNubVisionAppUseCase,
  AddNubVisionAppUseCaseImpl,
} from "../../domain/usecase/nubVisionApps/add/AddNubVisionAppUseCase";
import {
  DeleteNubVisionAppUseCase,
  DeleteNubVisionAppUseCaseImpl,
} from "../../domain/usecase/nubVisionApps/delete/DeleteNubVisionAppUseCase";
import {
  GetAllNubVisionAppsUseCase,
  GetAllNubVisionAppsUseCaseImpl,
} from "../../domain/usecase/nubVisionApps/getAll/GetAllNubVisionAppsUseCase";
import {
  UpdateNubVisionAppUseCase,
  UpdateNubVisionAppUseCaseImpl,
} from "../../domain/usecase/nubVisionApps/update/UpdateNubVisionAppUseCase";
import {
  UploadImageUseCase,
  UploadImageUseCaseImpl,
} from "../../domain/usecase/uploadImage/UploadImageUseCase";
import { provideApiBuilder } from "./NetworkModule";

function provideNetworkApi(): NetworkApi {
  return new NetworkApiImpl(provideApiBuilder());
}

function provideAuthRepository(): AuthRepository {
  return new AuthRepositoryImpl(provideNetworkApi());
}

export function provideLoginUseCase(): LoginUseCase {
  return new LoginUseCaseImpl(provideAuthRepository());
}

export function provideChangePasswordUseCase(): ChangePasswordUseCase {
  return new ChangePasswordUseCaseImpl(provideAuthRepository());
}

function provideUploadImageRepository(): UploadImageRepository {
  return new UploadImageRepositoryImpl(provideNetworkApi());
}

export function provideUploadImageUseCase(): UploadImageUseCase {
  return new UploadImageUseCaseImpl(provideUploadImageRepository());
}

function provideAndroidTVSettingsRepository(): AndroidTVSettingsRepository {
  return new AndroidTVSettingsRepositoryImpl(provideNetworkApi());
}

export function provideGetAndroidTvSettingsUseCase(): GetAndroidTVSettingsUseCase {
  return new GetAndroidTVSettingsUseCaseImpl(
    provideAndroidTVSettingsRepository()
  );
}

export function provideChangeBackgroundImageUseCase(): AndroidTVSettingsChangeBackgroundImageUseCase {
  return new AndroidTVSettingsChangeBackgroundImageUseCaseImpl(
    provideAndroidTVSettingsRepository()
  );
}

export function provideChangeTitleUseCase(): AndroidTVSettingsChangeTitleUseCase {
  return new AndroidTVSettingsChangeTitleUseCaseImpl(
    provideAndroidTVSettingsRepository()
  );
}

export function provideUpdateCurrentLauncherAppVersionUseCase(): AndroidTVSettingsUpdateCurrentLauncherAppVersionUseCase {
  return new AndroidTVSettingsUpdateCurrentLauncherAppVersionUseCaseImpl(
    provideAndroidTVSettingsRepository()
  );
}

export function provideAddHomeButtonUseCase(): AndroidTVSettingsAddHomeButtonUseCase {
  return new AndroidTVSettingsAddHomeButtonUseCaseImpl(
    provideAndroidTVSettingsRepository()
  );
}

export function provideDeleteHomeButtonUseCase(): AndroidTVSettingsDeleteHomeButtonUseCase {
  return new AndroidTVSettingsDeleteHomeButtonUseCaseImpl(
    provideAndroidTVSettingsRepository()
  );
}

export function provideUpdateHomeButtonUseCase(): AndroidTVSettingsUpdateHomeButtonUseCase {
  return new AndroidTVSettingsUpdateHomeButtonUseCaseImpl(
    provideAndroidTVSettingsRepository()
  );
}

function provideAndroidTVMessagesRepository(): AndroidTVMessagesRepository {
  return new AndroidTVMessagesRepositoryImpl(provideNetworkApi());
}

export function provideSendAndroidTvMessageUseCase(): SendAndroidTVMessageUseCase {
  return new SendAndroidTVMessageUseCaseImpl(
    provideAndroidTVMessagesRepository()
  );
}

export function provideGetAllAndroidTvMessagesUseCase(): GetAllAndroidTVMessagesUseCase {
  return new GetAllAndroidTVMessagesUseCaseImpl(
    provideAndroidTVMessagesRepository()
  );
}

function provideNubVisionAppsRepository(): NubVisionAppsRepository {
  return new NubVisionAppsRepositoryImpl(provideNetworkApi());
}

export function provideGetAllNubVisionAppsUseCase(): GetAllNubVisionAppsUseCase {
  return new GetAllNubVisionAppsUseCaseImpl(provideNubVisionAppsRepository());
}

export function provideAddNubVisionAppUseCase(): AddNubVisionAppUseCase {
  return new AddNubVisionAppUseCaseImpl(provideNubVisionAppsRepository());
}

export function provideUpdateNubVisionAppUseCase(): UpdateNubVisionAppUseCase {
  return new UpdateNubVisionAppUseCaseImpl(provideNubVisionAppsRepository());
}

export function provideDeleteNubVisionAppUseCase(): DeleteNubVisionAppUseCase {
  return new DeleteNubVisionAppUseCaseImpl(provideNubVisionAppsRepository());
}

function provideActivationCodeRepository(): ActivationCodeRepository {
  return new ActivationCodeRepositoryImpl(provideNetworkApi());
}
export function provideGetAllActivationCodesUseCase(): GetAllActivationCodesUseCase {
  return new GetAllActivationCodesUseCaseImpl(
    provideActivationCodeRepository()
  );
}
export function provideCreateActivationCodeUseCase(): CreateActivationCodeUseCase {
  return new CreateActivationCodeUseCaseImpl(provideActivationCodeRepository());
}
export function provideDeleteActivationCodeUseCase(): DeleteActivationCodeUseCase {
  return new DeleteActivationCodeUseCaseImpl(provideActivationCodeRepository());
}
