import { useEffect, useState } from "react";
import {
  ActivationCode,
  CreateActivationCodeRequest,
} from "../../../domain/model/ActivationCode";
import { useSnackbar } from "notistack";
import { State } from "../../../domain/model/ResponseState";
import { CreateActivationCodeUseCase } from "../../../domain/usecase/activationCode/create/UseCase";
import { GetAllActivationCodesUseCase } from "../../../domain/usecase/activationCode/getAll/UseCase";
import { DeleteActivationCodeUseCase } from "../../../domain/usecase/activationCode/delete/UseCase";
import { copyToClipboard } from "../../../core/utils/UtilsInJS";

export function ActivationCodesViewModel(
  createActivationCodeUseCase: CreateActivationCodeUseCase,
  getAllActivationCodesUseCase: GetAllActivationCodesUseCase,
  deleteActivationCodeUseCase: DeleteActivationCodeUseCase
) {
  const { enqueueSnackbar } = useSnackbar();
  const [isFetchingActivationCodes, setIsFetchingActivationCodes] =
    useState(false);
  const [isCreatingActivationCode, setIsCreatingActivationCode] =
    useState(false);

  const [activationCodes, setActivationCodes] = useState<Array<ActivationCode>>(
    []
  );

  const [createActivationCodeModel, setCreateActivationCodeModel] =
    useState<CreateActivationCodeRequest>({ expiresIn: 365, bulkNumber: 1 });

  const [searchText, setSearchText] = useState("");

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [showLoadMoreLoading, setShowLoadMoreLoading] = useState(false);
  const [skip, setSkip] = useState(-1);
  const limit = 40;

  const [createdActivationCodes, setCreatedActivationCodes] = useState<
    Array<ActivationCode>
  >([]);
  const [showActivationCodeCreatedDialog, setShowActivationCodeCreatedDialog] =
    useState(false);

  useEffect(() => {
    if (skip == 0) {
      getActivationCodes();
    }
  }, [skip]);

  useEffect(() => {
    if (!searchText) {
      setActivationCodes([]);
      setSkip(0);
    }
  }, [searchText]);

  async function createActivationCode() {
    setIsCreatingActivationCode(true);

    const response = await createActivationCodeUseCase.invoke(
      createActivationCodeModel
    );
    switch (response.responseState) {
      case State.Success:
        if (!response.data) return;
        setActivationCodes([]);
        setSkip(0);

        setCreatedActivationCodes(response.data);
        setShowActivationCodeCreatedDialog(true);

        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsCreatingActivationCode(false);
  }

  async function search() {
    if (!searchText) {
      return;
    }
    setIsFetchingActivationCodes(true);

    const response = await getAllActivationCodesUseCase.invoke(
      undefined,
      undefined,
      searchText
    );
    switch (response.responseState) {
      case State.Success:
        if (!response.data) return;
        setActivationCodes(response.data);

        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsFetchingActivationCodes(false);
  }

  async function deleteActivationCode(id: string) {
    var answer = window.confirm("Are you sure you want to delete this?");
    if (!answer) {
      return;
    }

    const response = await deleteActivationCodeUseCase.invoke(id);
    switch (response.responseState) {
      case State.Success:
        if (!response.data) return;
        setActivationCodes([]);
        setSkip(0);

        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
  }

  async function getActivationCodes() {
    if (skip == 0) setIsFetchingActivationCodes(true);
    else setShowLoadMoreLoading(true);

    const response = await getAllActivationCodesUseCase.invoke(skip, limit);
    switch (response.responseState) {
      case State.Success:
        if (!response.data) return;
        if (response.data.length < limit) setShowLoadMoreButton(false);
        else setShowLoadMoreButton(true);
        setActivationCodes(activationCodes?.concat(response.data));
        setSkip(skip + limit);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsFetchingActivationCodes(false);
    setShowLoadMoreLoading(false);
  }

  function copyAll() {
    var activationCodesText = "";
    createdActivationCodes.map((i) => {
      activationCodesText += `${i.code}\n`;
    });
    copyToClipboard(activationCodesText);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    isFetchingActivationCodes,
    isCreatingActivationCode,
    activationCodes,
    showLoadMoreButton,
    showLoadMoreLoading,
    getActivationCodes,
    createActivationCode,
    createActivationCodeModel,
    setCreateActivationCodeModel,
    deleteActivationCode,
    showActivationCodeCreatedDialog,
    setShowActivationCodeCreatedDialog,
    createdActivationCodes,
    copyAll,
    searchText,
    setSearchText,
    search,
  };
}
