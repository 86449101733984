import { Button, Card, Stack, Typography } from "@mui/material";
import { FIGTREE_FONT_FAMILY } from "../../data/constant/Constants";
import { COLOR_SECTION_GRAY } from "../../data/colors/Colors";
import React from "react";

interface Section {
  title: string;
  subtitle: string;
  minHeight?: string;
  layout: React.ReactElement;
}

export const SectionView: React.FC<Section> = ({
  title,
  subtitle,
  minHeight = "24vh",
  layout,
}: Section) => {
  return (
    <Card
      elevation={0}
      sx={{
        padding: 3,
        borderRadius: 6,
        background: COLOR_SECTION_GRAY,
      }}
    >
      <Stack minHeight={minHeight} justifyContent={"space-between"}>
        <Stack spacing={1}>
          <Typography
            variant="h5"
            fontFamily={FIGTREE_FONT_FAMILY}
            fontWeight={"600"}
          >
            {title}
          </Typography>

          <Typography
            variant="h6"
            fontFamily={FIGTREE_FONT_FAMILY}
            fontWeight={"100"}
          >
            {subtitle}
          </Typography>
        </Stack>

        {layout}
      </Stack>
    </Card>
  );
};
