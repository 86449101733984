import { ActivationCodeRepository } from "../../../../data/repository/activationCode/ActivationCodeRepository";
import { ActivationCode } from "../../../model/ActivationCode";
import { ResponseState, State } from "../../../model/ResponseState";

export interface GetAllActivationCodesUseCase {
  invoke: (
    skip?: number,
    limit?: number,
    q?: string
  ) => Promise<ResponseState<Array<ActivationCode>>>;
}

export class GetAllActivationCodesUseCaseImpl
  implements GetAllActivationCodesUseCase
{
  private repo: ActivationCodeRepository;

  constructor(repo: ActivationCodeRepository) {
    this.repo = repo;
  }

  async invoke(
    skip?: number,
    limit?: number,
    q?: string
  ): Promise<ResponseState<Array<ActivationCode>>> {
    try {
      let response = await this.repo.getActivationCodes(skip, limit, q);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
