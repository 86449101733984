import lightOrDarkImage from "@check-light-or-dark/image";
import dateAndTime from "date-and-time";

export async function isImageMainlyDark(img: string): Promise<boolean> {
  return (await lightOrDarkImage({ image: img })) == "dark";
}

export function formateDate(date: Date) {
  return dateAndTime.format(new Date(date), "DD MMM YYYY hh:mm A");
}

export function msToTime(duration: number) {
  var seconds: any = Math.floor((duration / 1000) % 60),
    minutes: any = Math.floor((duration / (1000 * 60)) % 60),
    hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + "h " + minutes + "m " + seconds + "s";
}
