export const BASE_URL = "https://api.launcher.nubvision.com";

export const API_ENDPOINTS = {
  LOGIN: "/auth/login",
  CHANGE_PASSWORD: "/auth/change_password",
  UPLOAD_IMAGE: "/upload_image",
  ANDROID_TV_SETTINGS: "/android_tv_settings",
  ANDROID_TV_SETTINGS_CHANGE_TITLE: "/android_tv_settings/title",
  ANDROID_TV_SETTINGS_UPDATE_CURRENT_LAUNCHER_APP_VERSION: "/android_tv_settings/current_launcher_app_version",
  ANDROID_TV_SETTINGS_CHANGE_BACKGROUND_IMAGE:
    "/android_tv_settings/background_image",
  ANDROID_TV_SETTINGS_HOME_BUTTONS: "/android_tv_settings/home_buttons",
  ANDROID_TV_MESSAGES: "/android_tv_messages",
  NUB_VISION_APPS: "/nub_vision_apps",
  ACTIVATION_CODES: "/activation_codes"
};
