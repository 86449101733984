import {
  Box,
  Card,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CustomImageView } from "../../component/CustomImageView";
import { ImagePicker } from "../../component/ImagePicker";
import { HTMLInputTypeAttribute, useEffect, useState } from "react";
import {
  COLOR_LIGHT_GRAY,
  COLOR_MAIN_COLOR_BLUE,
  COLOR_SECTION_GRAY,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { SectionView } from "../../component/Section";
import { FIGTREE_FONT_FAMILY } from "../../../data/constant/Constants";
import { HomeButton, HomeButtonAction } from "../../../domain/model/HomeButton";
import {
  STRINGS_ACTION_IS_NEEDED,
  STRINGS_APP_APK_DOWNLOAD_URL,
  STRINGS_CHOOSE_AN_ACTION,
  STRINGS_PACKAGE_ID,
  STRINGS_THE_DOWNLOAD_URL_USE_DOWNLOAD_APK,
  STRINGS_THIS_INFO_NEEDED_CREATING_HOME_BUTTON,
  STRINGS_TITLE_NEEDED_FOR_HOME_BUTTON,
  STRINGS_TYPE_A_TITLE,
  STRINGS_URL,
} from "../../../data/strings/Strings";

interface Props {
  selectedHomeButton?: HomeButton | null;
  handleHomeButtonCreated: (homeButton: HomeButton) => void;
}

export function AddOrEditHomeButtonView(props: Props) {
  const imageCardWidth = { xs: "94vw", sm: "96vw", md: "31vw", lg: "32vw" };
  const imageCardHeight = "36vh";

  const [homeButton, setHomeButton] = useState<HomeButton | null>({
    action: HomeButtonAction.URL_REDIRECT,
  });

  useEffect(() => {
    if (props.selectedHomeButton) {
      setHomeButton(props.selectedHomeButton);
    }
  }, []);

  useEffect(() => {
    if (homeButton) props.handleHomeButtonCreated(homeButton);
  }, [homeButton]);

  return (
    <Box minHeight={"40vh"} padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 4,
              width: imageCardWidth,
              height: imageCardHeight,
            }}
          >
            <Box
              zIndex={"1"}
              position={"absolute"}
              sx={{
                width: imageCardWidth,
                height: imageCardHeight,
                borderRadius: 4,
              }}
            >
              <CustomImageView
                width="100%"
                height="100%"
                scale={homeButton?.image ? "contain" : "cover"}
                src={
                  homeButton?.image ??
                  require("../../../assets/images/placeholder.png")
                }
                props={{
                  borderRadius: 4,
                  background: `linear-gradient(#002afe,#002afe,#0024d9,#000000,#000000)`,
                }}
              />
            </Box>
            <Box
              zIndex={"2"}
              position={"absolute"}
              sx={{ background: COLOR_WHITE, borderRadius: 4, margin: 2 }}
            >
              <ImagePicker
                size={"small"}
                handleImagePicked={(file: File) => {
                  if (!file) return;
                  setHomeButton({
                    ...homeButton,
                    image: URL.createObjectURL(file),
                    imageFile: file,
                  });
                }}
              />
            </Box>
          </Card>
        </Grid>

        <Grid container item xs={12} sm={12} md={8} xl={8} spacing={2}>
          <Grid item minHeight={imageCardHeight} xs={12} sm={12} md={6} xl={3}>
            <SectionView
              title={STRINGS_TYPE_A_TITLE}
              subtitle={STRINGS_TITLE_NEEDED_FOR_HOME_BUTTON}
              layout={
                <CustomTextField
                  value={homeButton?.title}
                  placeholder="Title"
                  handleChange={(txt) => {
                    setHomeButton({ ...homeButton, title: txt });
                  }}
                />
              }
              minHeight="29vh"
            />
          </Grid>
          <Grid item minHeight={imageCardHeight} xs={12} sm={12} md={6} xl={3}>
            <SectionView
              title={STRINGS_CHOOSE_AN_ACTION}
              subtitle={STRINGS_ACTION_IS_NEEDED}
              layout={
                <Select
                  sx={{ fontFamily: FIGTREE_FONT_FAMILY, borderRadius: 12 }}
                  inputProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          borderRadius: 4,
                        },
                      },
                    },
                  }}
                  value={homeButton?.action}
                  onChange={(e) => {
                    setHomeButton({
                      ...homeButton,
                      action: e.target.value as HomeButtonAction,
                    });
                  }}
                >
                  {Object.values(HomeButtonAction).map((action) => {
                    return (
                      <MenuItem
                        key={action}
                        sx={{ fontFamily: FIGTREE_FONT_FAMILY }}
                        value={action}
                      >
                        {action}
                      </MenuItem>
                    );
                  })}
                </Select>
              }
              minHeight="29vh"
            />
          </Grid>
          <Grid
            item
            minHeight={imageCardHeight}
            xs={12}
            sm={12}
            md={6}
            xl={3}
            style={
              homeButton?.action === HomeButtonAction.DEVICE_APPS_SCREEN ||
              homeButton?.action === HomeButtonAction.DEVICE_SETTINGS
                ? { filter: "blur(6px)", pointerEvents: "none" }
                : undefined
            }
          >
            <SectionView
              title={`Type the ${
                homeButton?.action === HomeButtonAction.URL_REDIRECT
                  ? STRINGS_URL
                  : STRINGS_PACKAGE_ID
              }`}
              subtitle={STRINGS_THIS_INFO_NEEDED_CREATING_HOME_BUTTON}
              layout={
                <>
                  {homeButton?.action === HomeButtonAction.URL_REDIRECT && (
                    <CustomTextField
                      value={homeButton?.url}
                      placeholder={STRINGS_URL}
                      handleChange={(txt) => {
                        setHomeButton({ ...homeButton, url: txt });
                      }}
                    />
                  )}
                  {homeButton?.action === HomeButtonAction.CUSTOM_APP && (
                    <CustomTextField
                      value={homeButton?.packageId}
                      placeholder={STRINGS_PACKAGE_ID}
                      handleChange={(txt) => {
                        setHomeButton({ ...homeButton, packageId: txt });
                      }}
                    />
                  )}
                </>
              }
              minHeight="29vh"
            />
          </Grid>
          <Grid
            item
            minHeight={"36vh"}
            xs={12}
            sm={12}
            md={6}
            xl={3}
            style={
              homeButton?.action === HomeButtonAction.CUSTOM_APP
                ? undefined
                : { filter: "blur(6px)", pointerEvents: "none" }
            }
          >
            <SectionView
              title={`Type the ${
                homeButton?.action === HomeButtonAction.CUSTOM_APP
                  ? STRINGS_APP_APK_DOWNLOAD_URL
                  : ""
              }`}
              subtitle={STRINGS_THE_DOWNLOAD_URL_USE_DOWNLOAD_APK}
              layout={
                <>
                  {homeButton?.action === HomeButtonAction.CUSTOM_APP && (
                    <CustomTextField
                      value={homeButton?.appApkDownloadUrl}
                      placeholder={STRINGS_APP_APK_DOWNLOAD_URL}
                      handleChange={(txt) => {
                        setHomeButton({
                          ...homeButton,
                          appApkDownloadUrl: txt,
                        });
                      }}
                    />
                  )}
                </>
              }
              minHeight="29vh"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

interface customTextFieldProps {
  value?: string;
  placeholder: string;
  isFullWidth?: boolean;
  handleChange: (txt: string) => void;
  required?: boolean;
  showTitle?: boolean;
  type?: HTMLInputTypeAttribute;
}
export function CustomTextField(props: customTextFieldProps) {
  return (
    <Stack spacing={1} width={"100%"}>
      {props.showTitle && (
        <Typography fontWeight={"bold"} variant="body1" color={"black"}>
          {props.placeholder}
          {props.required ? " *" : ""}
        </Typography>
      )}
      <TextField
        value={props.value ?? undefined}
        type={props.type ?? "text"}
        required={props.required}
        autoComplete="off"
        fullWidth={props.isFullWidth}
        placeholder={`${props.placeholder}${props.required ? " *" : ""}`}
        InputProps={{
          style: {
            fontFamily: FIGTREE_FONT_FAMILY,
            borderRadius: 44,
          },
        }}
        onChange={(e) => props.handleChange(e.target.value)}
      />
    </Stack>
  );
}
