import { ActivationCodeRepository } from "../../../../data/repository/activationCode/ActivationCodeRepository";
import {
  ActivationCode,
  CreateActivationCodeRequest,
} from "../../../model/ActivationCode";
import { ResponseState, State } from "../../../model/ResponseState";

export interface CreateActivationCodeUseCase {
  invoke: (
    model: CreateActivationCodeRequest
  ) => Promise<ResponseState<Array<ActivationCode>>>;
}

export class CreateActivationCodeUseCaseImpl
  implements CreateActivationCodeUseCase
{
  private repo: ActivationCodeRepository;

  constructor(repo: ActivationCodeRepository) {
    this.repo = repo;
  }

  async invoke(
    model: CreateActivationCodeRequest
  ): Promise<ResponseState<Array<ActivationCode>>> {
    try {
      let response = await this.repo.createActivationCode(model);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
