// login
export const STRINGS_LOGIN = "Login"
export const STRINGS_EMAIL = "Email"
export const STRINGS_PASSWORD = "Password"
export const STRINGS_NUB_VISION_2 = "Nub Vision 2.0"

// home
export const STRINGS_BACKGROUND_IMAGE = "Background Image"
export const STRINGS_BACKGROUND_IMAGE_DESCRIPTION = "Choose an image to be set for the background of the home page in the android TV app"
export const STRINGS_CHOOSE = "Choose"
export const STRINGS_HOME_BUTTON = "Home Button"
export const STRINGS_HOME_BUTTON_DESCRIPTION = "Add a button to the home page in the android TV app"
export const STRINGS_NUB_VISION_APP = "Nub Vision App"
export const STRINGS_NUB_VISION_APPS = "Nub Vision Apps"
export const STRINGS_NUB_VISION_APP_DESCRIPTION = "Add an app to the Nub Vision apps in the android TV app"
export const STRINGS_ADD = "Add"
export const STRINGS_MESSAGE = "Message"
export const STRINGS_MESSAGE_DESCRIPTION = "Send a simple message to all the android TV app users"
export const STRINGS_ACTIVATION_CODES = "Activation Codes"
export const STRINGS_ACTIVATION_CODES_DESCRIPTION = "Create as many activation codes as you like in here"
export const STRINGS_GENERATE_ACTIVIATION_CODE = "Generate Activation Code"
export const STRINGS_GENERATE_ACTIVIATION_CODE_DESCRIPTION = "You can create 1 activation code or more at once"
export const STRINGS_GO = "Go"
export const STRINGS_CURRENT_LAUNCHER_APP_VERSION = "Current Launcher App Version"
export const STRINGS_CURRENT_LAUNCHER_APP_VERSION_DESCRIPTION = "Update the current launcher app version when there is a new version"
export const STRINGS_SEND = "Send"
export const STRINGS_UPDATE = "Update"
export const STRINGS_PREVIEW = "Preview"
export const STRINGS_PREVIEW_DESCRIPTION = "This is how the android TV app is going to look like"
export const STRINGS_NUB_VISION_APPS_DESCRIPTION = "These are the Nub Vision apps shown in the android tv app"
export const STRINGS_CHOOSE_AN_IMAGE = "Choose an Image"
export const STRINGS_UPLOAD = "Upload"
export const STRINGS_CANCEl = "Cancel"
export const STRINGS_CANCEl_DESRIPTION = "Are you sure you want to cancel this?"
export const STRINGS_SAVE = "Save"
export const STRINGS_DONE = "Done"
export const STRINGS_DELETE = "Delete"
export const STRINGS_OK = "Ok"
export const STRINGS_HOME_BUTTONS = "Home Buttons"
export const STRINGS_PACKAGE_ID = "Package ID"
export const STRINGS_URL = "URL"
export const STRINGS_TITLE_CANNOT_BE_EMPTY = "Title cannot be empty"
export const STRINGS_CURRENT_VERSION = "Current Version"
export const STRINGS_APP_APK_DOWNLOAD_URL = "App Apk Download Url"
export const STRINGS_THE_DOWNLOAD_URL_USE_DOWNLOAD_APK = "The download url that the app will use to download the apk"
export const STRINGS_THIS_INFO_NEEDED_CREATING_HOME_BUTTON = "This info is needed for creating the home button"
export const STRINGS_THIS_INFO_NEEDED_CREATING_NUB_VISION_APP = "This info is needed for creating the Nub Vision app"
export const STRINGS_CHOOSE_AN_ACTION = "Choose an Action"
export const STRINGS_ACTION_IS_NEEDED = "Action is needed"
export const STRINGS_TYPE_A_TITLE = "Type a Title"
export const STRINGS_TITLE_NEEDED_FOR_HOME_BUTTON = "Title is needed for the home button"
export const STRINGS_TITLE_NEEDED_FOR_NUB_VISION_APP = "Title is needed for the Nub Vision app"
export const STRINGS_PLEASE_FILL_OUT_ALL_INFO = "Please fill out all the info!"
export const STRINGS_PLEASE_CHOOSE_AN_IMAGE_FIRST = "Please choose an image first!"
export const STRINGS_TYPE_A_VERSION = "Please type a version"
export const STRINGS_PREVIOUS_MESSAGES = "Previous Messages"
export const STRINGS_YOU_CAN_SEE_PREVIOUS_MESSAGE_HERE = "You can see the previously sent messages in here"
export const STRINGS_SEND_MESSAGE = "Send Message"
export const STRINGS_YOUR_MESSAGE_WILL_BE_SENT_ALL_USERS = "Your message will be sent to all the android TV users"
export const STRINGS_PASSWORDS_ARE_NOT_MATCHING = "Passwords are not matching"
export const STRINGS_CHANGE_PASSWORD = "Change Password"
export const STRINGS_INSERT_PASSWORD = "You can insert your new password here"
export const STRINGS_CHANGE = "Change"
export const STRINGS_YOU_NEED_TO_LOGIN_AGAIN = "You need to login again"