import { Box, Grid } from "@mui/material";
import { HomeSection } from "../../component/HomeSection";
import { useState } from "react";
import { SectionBottomDrawerView } from "./SectionBottomDrawer";
import {
  STRINGS_ACTIVATION_CODES,
  STRINGS_ACTIVATION_CODES_DESCRIPTION,
  STRINGS_ADD,
  STRINGS_BACKGROUND_IMAGE,
  STRINGS_BACKGROUND_IMAGE_DESCRIPTION,
  STRINGS_CHOOSE,
  STRINGS_CURRENT_LAUNCHER_APP_VERSION,
  STRINGS_CURRENT_LAUNCHER_APP_VERSION_DESCRIPTION,
  STRINGS_DELETE,
  STRINGS_DONE,
  STRINGS_GO,
  STRINGS_HOME_BUTTON,
  STRINGS_HOME_BUTTON_DESCRIPTION,
  STRINGS_MESSAGE,
  STRINGS_MESSAGE_DESCRIPTION,
  STRINGS_NUB_VISION_APP,
  STRINGS_NUB_VISION_APP_DESCRIPTION,
  STRINGS_NUB_VISION_APPS,
  STRINGS_NUB_VISION_APPS_DESCRIPTION,
  STRINGS_PREVIEW,
  STRINGS_PREVIEW_DESCRIPTION,
  STRINGS_SAVE,
  STRINGS_SEND,
  STRINGS_UPDATE,
  STRINGS_UPLOAD,
} from "../../../data/strings/Strings";
import { SectionView } from "../../component/Section";
import { HomeViewModel } from "../home/HomeViewModel";
import { AddOrEditHomeButtonView } from "../addHomeButton/AddOrEditHomeButtonView";
import { ChooseBackgroundImageView } from "../chooseBackgroundImage/ChooseBackgroundImageView";
import {
  provideAddHomeButtonUseCase,
  provideAddNubVisionAppUseCase,
  provideChangeBackgroundImageUseCase,
  provideDeleteHomeButtonUseCase,
  provideDeleteNubVisionAppUseCase,
  provideGetAndroidTvSettingsUseCase,
  provideUpdateCurrentLauncherAppVersionUseCase,
  provideUpdateHomeButtonUseCase,
  provideUpdateNubVisionAppUseCase,
  provideUploadImageUseCase,
} from "../../di/UseCasesModule";
import { PreviewView } from "../preview/PreviewView";
import useWindowDimensions from "../../../core/utils/UseWindowDimensions";
import { HomeButton } from "../../../domain/model/HomeButton";
import { SendMessageView } from "../sendMessage/SendMessageView";
import { AddOrEditNubVisionAppView } from "../addNubVisionApp/AddOrEditNubVisionAppView";
import { NubVisionApp } from "../../../domain/model/NubVisionApp";
import { NubVisionAppsPreviewView } from "../nubVisionAppsPreview/NubVisionAppsPreviewView";
import { UpdateCurrentLauncherAppVersionView } from "../updateCurrentLauncherAppVersion/UpdateCurrentLauncherAppVersionView";
import { ActivationCodesView } from "../activationCode/ActivationCodesView";

export function SectionsView() {
  const {
    uploadBackgroundImage,
    getLoading,
    setLoading,
    handleChosenImageFile,
    handleHomeButtonCreated,
    handleNubVisionAppCreated,
    addHomeButton,
    deleteHomeButton,
    updateHomeButton,
    addNubVisionApp,
    deleteNubVisionApp,
    updateNubVisionApp,
    setCurrentLauncherAppVersion,
    currentLauncherAppVersion,
    updateCurrentLauncherAppVersion,
    currentLauncherAppVersionLoading,
  } = HomeViewModel(
    provideUploadImageUseCase(),
    provideChangeBackgroundImageUseCase(),
    provideAddHomeButtonUseCase(),
    provideDeleteHomeButtonUseCase(),
    provideUpdateHomeButtonUseCase(),
    provideAddNubVisionAppUseCase(),
    provideUpdateNubVisionAppUseCase(),
    provideDeleteNubVisionAppUseCase(),
    provideUpdateCurrentLauncherAppVersionUseCase(),
    provideGetAndroidTvSettingsUseCase()
  );

  const { width } = useWindowDimensions();

  const [selectedHomeButton, setSelectedHomeButton] =
    useState<HomeButton | null>(null);

  const [selectedNubVisionApp, setSelectedNubVisionApp] =
    useState<NubVisionApp | null>(null);

  const [open, setOpen] = useState(false);
  const [currentBottomDrawableTitle, setCurrentBottomDrawableTitle] =
    useState("");
  const [
    currentBottomDrawableLayoutIndex,
    setCurrentBottomDrawableLayoutIndex,
  ] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedHomeButton(null);
    setSelectedNubVisionApp(null);
    setOpen(false);
  };

  function getSectionsDrawerDetails(): {
    layout: React.ReactElement;
    primaryButtonActionTitle: string;
    primaryButtonActionOnclick: () => void;
    secondaryButtonActionTitle?: string | null;
    secondaryButtonActionOnclick?: () => void;
  } {
    return getSectionsBottomDrawerDetails(currentBottomDrawableLayoutIndex);
  }

  function getSectionsBottomDrawerDetails(index: number): {
    layout: React.ReactElement;
    primaryButtonActionTitle: string;
    primaryButtonActionOnclick: () => void;
    secondaryButtonActionTitle?: string | null;
    secondaryButtonActionOnclick?: () => void;
  } {
    switch (index) {
      case 0:
        return {
          layout: <ActivationCodesView />,
          primaryButtonActionTitle: STRINGS_DONE,
          primaryButtonActionOnclick: handleClose,
        };
      case 1:
        return {
          layout: (
            <ChooseBackgroundImageView
              handleBackgroundImageChosen={handleChosenImageFile}
            />
          ),
          primaryButtonActionTitle: STRINGS_UPLOAD,
          primaryButtonActionOnclick: () => uploadBackgroundImage(handleClose),
        };
      case 2:
        return {
          layout: (
            <AddOrEditHomeButtonView
              selectedHomeButton={selectedHomeButton}
              handleHomeButtonCreated={handleHomeButtonCreated}
            />
          ),
          primaryButtonActionTitle: selectedHomeButton
            ? STRINGS_SAVE
            : STRINGS_ADD,
          primaryButtonActionOnclick: () =>
            selectedHomeButton
              ? updateHomeButton(handleClose)
              : addHomeButton(handleClose),
          secondaryButtonActionTitle: selectedHomeButton
            ? STRINGS_DELETE
            : null,
          secondaryButtonActionOnclick: () => {
            deleteHomeButton(selectedHomeButton?._id, handleClose);
          },
        };
      case 3:
        return {
          layout: (
            <AddOrEditNubVisionAppView
              selectedNubVision={selectedNubVisionApp}
              handleNubVisionAppCreated={handleNubVisionAppCreated}
            />
          ),
          primaryButtonActionTitle: selectedNubVisionApp
            ? STRINGS_SAVE
            : STRINGS_ADD,
          primaryButtonActionOnclick: () =>
            selectedNubVisionApp
              ? updateNubVisionApp(handleClose)
              : addNubVisionApp(handleClose),
          secondaryButtonActionTitle: selectedNubVisionApp
            ? STRINGS_DELETE
            : null,
          secondaryButtonActionOnclick: () => {
            deleteNubVisionApp(selectedNubVisionApp?._id, handleClose);
          },
        };
      case 4:
        return {
          layout: <SendMessageView />,
          primaryButtonActionTitle: STRINGS_DONE,
          primaryButtonActionOnclick: handleClose,
        };
      default:
        return {
          layout: (
            <UpdateCurrentLauncherAppVersionView
              loading={currentLauncherAppVersionLoading}
              currentVersion={currentLauncherAppVersion}
              handleCurrentVersionChanged={setCurrentLauncherAppVersion}
            />
          ),
          primaryButtonActionTitle: STRINGS_UPDATE,
          primaryButtonActionOnclick: () =>
            updateCurrentLauncherAppVersion(handleClose),
        };
    }
  }

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} xl={3}>
          <HomeSection
            title={STRINGS_ACTIVATION_CODES}
            subtitle={STRINGS_ACTIVATION_CODES_DESCRIPTION}
            buttonTitle={STRINGS_GO}
            Onclick={() => {
              setCurrentBottomDrawableTitle(STRINGS_ACTIVATION_CODES);
              setCurrentBottomDrawableLayoutIndex(0);
              handleClickOpen();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3}>
          <HomeSection
            title={STRINGS_BACKGROUND_IMAGE}
            subtitle={STRINGS_BACKGROUND_IMAGE_DESCRIPTION}
            buttonTitle={STRINGS_CHOOSE}
            Onclick={() => {
              setCurrentBottomDrawableTitle(STRINGS_BACKGROUND_IMAGE);
              setCurrentBottomDrawableLayoutIndex(1);
              handleClickOpen();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3}>
          <HomeSection
            title={STRINGS_HOME_BUTTON}
            subtitle={STRINGS_HOME_BUTTON_DESCRIPTION}
            buttonTitle={STRINGS_ADD}
            Onclick={() => {
              setCurrentBottomDrawableTitle(STRINGS_HOME_BUTTON);
              setCurrentBottomDrawableLayoutIndex(2);
              handleClickOpen();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={3}>
          <HomeSection
            title={STRINGS_NUB_VISION_APP}
            subtitle={STRINGS_NUB_VISION_APP_DESCRIPTION}
            buttonTitle={STRINGS_ADD}
            Onclick={() => {
              setCurrentBottomDrawableTitle(STRINGS_NUB_VISION_APP);
              setCurrentBottomDrawableLayoutIndex(3);
              handleClickOpen();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <HomeSection
            title={STRINGS_MESSAGE}
            subtitle={STRINGS_MESSAGE_DESCRIPTION}
            buttonTitle={STRINGS_SEND}
            Onclick={() => {
              setCurrentBottomDrawableTitle(STRINGS_MESSAGE);
              setCurrentBottomDrawableLayoutIndex(4);
              handleClickOpen();
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} xl={6}>
          <HomeSection
            title={STRINGS_CURRENT_LAUNCHER_APP_VERSION}
            subtitle={STRINGS_CURRENT_LAUNCHER_APP_VERSION_DESCRIPTION}
            buttonTitle={STRINGS_UPDATE}
            Onclick={() => {
              setCurrentBottomDrawableTitle(
                STRINGS_CURRENT_LAUNCHER_APP_VERSION
              );
              setCurrentBottomDrawableLayoutIndex(5);
              handleClickOpen();
            }}
          />
        </Grid>
        {width > 500 && (
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <SectionView
              title={STRINGS_PREVIEW}
              subtitle={STRINGS_PREVIEW_DESCRIPTION}
              minHeight="90vh"
              layout={
                <PreviewView
                  handleOnHomeButtonClicked={(homeButton) => {
                    setSelectedHomeButton(homeButton);
                    setCurrentBottomDrawableTitle(STRINGS_HOME_BUTTON);
                    setCurrentBottomDrawableLayoutIndex(2);
                    handleClickOpen();
                  }}
                />
              }
            />
          </Grid>
        )}
        {width > 500 && (
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <SectionView
              title={STRINGS_NUB_VISION_APPS}
              subtitle={STRINGS_NUB_VISION_APPS_DESCRIPTION}
              minHeight="90vh"
              layout={
                <NubVisionAppsPreviewView
                  handleOnNubVisionAppClicked={(nubVisionApp) => {
                    setSelectedNubVisionApp(nubVisionApp);
                    setCurrentBottomDrawableTitle(STRINGS_HOME_BUTTON);
                    setCurrentBottomDrawableLayoutIndex(3);
                    handleClickOpen();
                  }}
                />
              }
            />
          </Grid>
        )}
      </Grid>

      <SectionBottomDrawerView
        open={open}
        isLoading={getLoading()}
        handleLoading={(loading) => {
          setLoading(loading);
        }}
        title={currentBottomDrawableTitle}
        layout={getSectionsDrawerDetails().layout}
        primaryActionButtonTitle={
          getSectionsDrawerDetails().primaryButtonActionTitle
        }
        primaryActionButtonOnClick={
          getSectionsDrawerDetails().primaryButtonActionOnclick
        }
        secondaryActionButtonTitle={
          getSectionsDrawerDetails().secondaryButtonActionTitle
        }
        secondaryActionButtonOnClick={
          getSectionsDrawerDetails().secondaryButtonActionOnclick
        }
        handleClose={handleClose}
      />
    </Box>
  );
}
